import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Layout from '../components/Layout'

import Text from '../components/common/Text'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 - Page Not Found</title>
      <meta name="description" content="Page not found" />
    </Helmet>

    <Container>
      <Text center html>
        <h1 center>Page Not Found</h1>
        Please return <Link to="/">home</Link> or use the menu to navigate to a
        different page.
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
